import(/* webpackMode: "eager" */ "/app/node_modules/@emergn-infinity/ids-theme-vfq/assets/vfq-ds/tokens/_variables.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/simplebar/dist/simplebar.min.css");
;
import(/* webpackMode: "eager" */ "/app/public/styles/styles.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/app.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/loading/loading.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/providers/material-theme-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/providers/notification-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/providers/privacy-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/providers/redux-provider.tsx");
