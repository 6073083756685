'use client';

import { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';

import useScrollIntoView from '@/hooks/use-scroll-into-view';
import { useAppDispatch, useLinkInterceptor } from '@/hooks';
import { AuthToken } from '@/services/auth.service';
import { identifyUser, initializeHeap } from '@/services/heap.service';
import { Keys } from '@/constants';
import { Preferences, User } from '@/interfaces';
import { useUpdateLastActiveDateMutation } from '@/redux/api/base-api-slice';
import { setUser } from '@/redux/user-slice';

const CookieAcceptance = dynamic(() => import('@/components/cookie-acceptance/cookie-acceptance'), { ssr: false });

const auth = new AuthToken();

interface AppProps {
  children: any;
  cookiePreferences: Preferences;
  loggedUser: User | null;
}

export default function App({ children, cookiePreferences, loggedUser }: Readonly<AppProps>) {
  const cookiesNotSet = Object.values(cookiePreferences).includes(null);

  const [showCookieModal, setShowCookieModal] = useState(cookiesNotSet);

  const [updateLastActiveDateMutation] = useUpdateLastActiveDateMutation();
  const dispatch = useAppDispatch();
  const pathname = usePathname();

  useLinkInterceptor();

  useScrollIntoView();

  useEffect(() => {
    localStorage.setItem(Keys.IDS_THEME, 'vfq-ds');
    localStorage.setItem(Keys.IDS_CUSTOM_PATH, '/app/assets/');

    // refresh lastActiveDate of the current user
    if (auth.hasValidToken()) {
      updateLastActiveDateMutation();
    }
  }, [updateLastActiveDateMutation]);

  useEffect(() => {
    if (loggedUser?.crm?.contactId) {
      const _hsq = (window._hsq = window._hsq || []);
      _hsq.push(['setPath', `/app${pathname}`], ['trackPageView']);
    }
  }, [loggedUser, pathname]);

  useEffect(() => {
    if (cookiePreferences?.analytics) {
      initializeHeap();

      if (loggedUser) {
        identifyUser(loggedUser);
      }
    }
  }, [loggedUser, cookiePreferences?.analytics]);

  useEffect(() => {
    // update redux with the logged user
    if (loggedUser) dispatch(setUser(loggedUser));
  }, [loggedUser, dispatch]);

  const handleCloseModal = () => {
    setShowCookieModal(false);
  };

  useEffect(() => {
    if (cookiePreferences?.analytics) {
      // signal clarity to collect cookies
      window.clarity('consent');
    }
  }, [cookiePreferences?.analytics]);

  return (
    <>
      {showCookieModal && <CookieAcceptance closeModal={handleCloseModal} showModal />}
      {children}
    </>
  );
}
