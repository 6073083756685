import { useCallback } from 'react';

import { ApiMethods, errorMessageCodeMap, ErrorMessage } from '@/constants/api';
import { getBackendUrl } from '@/constants/api/api';
import { useNotification } from '@/providers/notification-context';

type SendRequestType = (
  url: string,
  method: ApiMethods,
  body?: BodyInit,
  headers?: HeadersInit,
  showError?: boolean,
) => Promise<{ [key: string]: any; statusCode: number }>;

export function useApi() {
  const { openErrorNotification } = useNotification();
  const sendRequest: SendRequestType = (
    url,
    method,
    body?,
    headers?,
    isBlob = false,
    showError = true,
  ): Promise<{ [key: string]: any; statusCode: number }> => {
    return fetch(getBackendUrl(url), { method: method, body, headers }).then(
      async (res) => {
        if (isBlob) {
          const blob = await res.blob();
          const error = errorMessageCodeMap[res.status];
          if (showError && error) {
            openErrorNotification(error);
          }
          return { data: blob, statusCode: res.status };
        }
        const data = await res.json();
        const error = errorMessageCodeMap[res.status] || data.message;
        if (error) {
          if (showError) {
            openErrorNotification(error);
          }
          return { ...data, statusCode: res.status };
        }
        return { data: data.data, statusCode: res.status };
      },
      (error) => {
        if (showError) {
          console.log(error);
          openErrorNotification(ErrorMessage.SOMETHING_WENT_WRONG);
        }
        return { ...error, statusCode: 0 };
      },
    );
  };

  const memoizedSendRequest = useCallback(sendRequest, [openErrorNotification]);

  return { sendRequest: memoizedSendRequest };
}
