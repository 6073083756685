import React from 'react';
import clsx from 'clsx';
import { IdsFieldWrapper, IdsPasswordInput } from '@emergn-infinity/ids-react';

import { VFQPasswordFieldProps } from './interfaces';

const VFQPasswordField: React.FC<VFQPasswordFieldProps> = ({
  id,
  labelName,
  name,
  text,
  errorText,
  value,
  children,
  onBlur,
  onFocus,
  isRequired,
  className,
  dataTestId,
  ...props
}: React.PropsWithChildren<VFQPasswordFieldProps>) => {
  const onBlurField = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onBlur?.(e);
  };
  const onFocusField = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onFocus?.(e);
  };

  return (
    <div className={clsx('PasswordField', className)}>
      <IdsFieldWrapper
        wrapperLabel={labelName}
        helperText={text}
        helperInvalidText={errorText}
        isInvalid={!!errorText}
        isValid={!errorText && !!value}
        isRequired={isRequired}
        customClasses="is-fullwidth"
      >
        <IdsPasswordInput
          {...props}
          themeName="vfq"
          rest={{
            id,
            name,
            value,
            required: isRequired,
            ariaLabel: labelName,
            onBlur: onBlurField,
            onFocus: onFocusField,
            maxLength: '50',
            'data-elp-testid': dataTestId,
          }}
        />
      </IdsFieldWrapper>
      {children}
    </div>
  );
};

export default VFQPasswordField;
