import { CmsColor } from '../general/cms-colors';

export interface KanbanCard {
  id: string;
  name: string;
  shortDescription: string;
  duration: number;
  type: KanbanCardType;
  status: number;
  courseName?: string;
  pathwayName?: string;
  quizPassed: boolean;
  length: number;
  courseId?: string;
  pathwayId?: string;
  cohortId?: string;
  dueDate?: Date;
  isActive?: boolean;
  color?: CmsColor;
}

export enum KanbanCardType {
  MODULE = 'module',
  COURSE_ASSESSMENT = 'course-assessment',
  PATHWAY_ASSESSMENT = 'pathway-assessment',
  CUSTOM_PATHWAY_ASSESSMENT = 'custom-pathway-assessment',
  ASSIGNMENT = 'assignment',
}
