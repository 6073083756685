'use client';

import { IdsSpinner } from '@emergn-infinity/ids-react';

import './loading.scss';

const LoadingComponent = () => {
  return (
    <div className="LoadingContainer">
      <IdsSpinner size="md" label="Loading" />
    </div>
  );
};

export default LoadingComponent;
